<template>
  <v-app>
    <NavBar v-if="publicLinks.indexOf($route.name) > -1" />
    <NavBarAdmin v-if="adminLinks.indexOf($route.name) > -1" />
    <v-main>
      <transition name="slide-fade" mode="out-in">
        <router-view />
      </transition>
    </v-main>
    <Footer v-if="publicLinks.indexOf($route.name) > -1" />
    <FooterAdmin v-if="adminLinks.indexOf($route.name) > -1" />
  </v-app>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
import NavBarAdmin from "@/components/admin/NavBarAdmin";
import FooterAdmin from "@/components/admin/FooterAdmin";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "App",
  components: {
    NavBar,
    Footer,
    NavBarAdmin,
    FooterAdmin,
  },
  data() {
    return {
      publicLinks: [
        "Home",
        "PropertyDetails",
        "VirtualTour",
        "Neighborhood",
        "ApplyHere",
      ],
      adminLinks: [
        "Admin",
        "System",
        "SideNavigationAdmin",
        "PropertyDetailsAdmin",
        "NeighborhoodAdmin",
        "VirtualTourAdmin",
        "ApplyHereAdmin",
        "ContactUsAdmin",
        "TVAdmin",
      ],
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["storeInit", "updateControls", "updateControlsAdmin"]),
  },
  created: function () {
    this.storeInit();
    this.updateControls();
    this.updateControlsAdmin();
  },
};
</script>

<style>
h1 {
  font-family: "Arial";
  text-indent: 0px;
  font-size: 1.625em;
  transform: scale(1.2, 1);
}

.h1-sm {
  font-family: "Arial";
  font-size: 1.375em;
}

h2 {
  font-family: "Arial";
  text-indent: 0px;
  font-size: 1.375em;
  transform: scale(1.15, 1);
}

.h2-sm {
  font-family: "Arial";
  font-size: 1em;
}

.padless {
  padding: 0px;
}

.padding-sm {
  padding: 5px;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.page-top-margin {
  height: 50px;
}

.list-header {
  font-size: 1.2em;
  text-decoration: underline;
  padding-bottom: 15px;
}

.list-header-sm {
  font-size: 1.1em;
}

.center {
  text-align: center;
}

/* Transitions  */
.slide-fade-enter {
  transform: translateX(10px);
  opacity: 0;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.2s ease;
}

.slide-fade-leave-to {
  transform: translateX(-10px);
  opacity: 0;
}
</style>
